<template>
  <div>
    <div class="tel">
      <img
        src="../../assets/image/Sports.png"
        alt=""
        style="width: 20px; height: 23px"
      />
      <p class="telnumber">体育项目</p>
      <p class="asterisk">*</p>
      <el-select
        placeholder="请选择运动项目"
        filterable
        v-model="info.sports"
        :disabled="step != 2 && step != 5"
        clearable
      >
        <el-option
          v-for="item in options1"
          :key="item.itemValue"
          :label="item.itemText"
          :value="item.itemValue"
        >
        </el-option>
      </el-select>
    </div>
    <div class="tel">
      <img
        src="../../assets/image/user2.png"
        alt=""
        style="width: 20px; height: 23px"
      />
      <p class="telnumber">裁判员级别</p>
      <p class="asterisk" style="margin-right: 41px">*</p>
      <el-select
        placeholder="请选择裁判员级别"
        v-model="info.refereeLevel"
        :disabled="step == 3"
        clearable
      >
        <el-option
          v-for="item in options2"
          :key="item.itemValue"
          :label="item.itemText"
          :value="item.itemValue"
        >
        </el-option>
      </el-select>
    </div>
    <div class="tel">
      <img
        src="../../assets/image/Registration4.png"
        alt=""
        style="width: 20px; height: 23px"
      />
      <p class="telnumber">注册所在区</p>
      <p class="asterisk" style="margin-right: 41px">*</p>
      <el-select
        placeholder="请选择"
        v-model="info.certificateArea"
        :disabled="step == 3"
        clearable
      >
        <el-option
          v-for="item in options3"
          :key="item.itemValue"
          :label="item.itemText"
          :value="item.itemValue"
        >
        </el-option>
      </el-select>
    </div>
    <div class="tel">
      <img
        src="../../assets/image/unit.png"
        alt=""
        style="width: 20px; height: 23px"
      />
      <p class="telnumber">发证单位</p>
      <p class="asterisk">*</p>
      <!-- <el-select placeholder="请选择" v-model="info.certificateUnit" :disabled="step == 3">
				<el-option v-for="item in options4" :key="item.itemValue" :label="item.itemText"
					:value="item.itemValue">
				</el-option>
			</el-select> -->
      <input type="text" v-model="info.certificateUnit" :disabled="step == 3" />
    </div>
    <div class="tel">
      <img
        src="../../assets/image/date2.png"
        alt=""
        style="width: 23px; height: 23px"
      />
      <p class="telnumber">发证日期</p>
      <p class="asterisk" style="margin-right: 56px">*</p>
      <!-- <el-date-picker v-model="info.certificateDate" :disabled="step == 3" type="date" placeholder="选择日期"
				value-format='yyyy-MM-dd' format='yyyy-MM-dd'>
			</el-date-picker> -->
      <el-date-picker
        style="width: 312px"
        v-model="info.certificateDate"
        type="month"
        value-format="yyyy-MM"
        format="yyyy-MM"
        :disabled="step == 3"
        placeholder="选择日期"
      >
      </el-date-picker>
    </div>

    <div class="tel">
      <img
        src="../../assets/image/certificate.png"
        alt=""
        style="width: 20px; height: 23px"
      />
      <p class="telnumber">证书第一页</p>
      <p class="asterisk">*</p>
      <div class="upload-box">
        <div class="upload">
          <SingleImage
            @uploadSuccess="handleSuccess1"
            :disabled="step == 3"
            :fileList="fileList1"
            @uploaddelete="uploaddelete"
          ></SingleImage>
          <div class="example">
            <p>正确示范</p>
            <img src="../../assets/image/first.jpg" alt="" />
          </div>
        </div>
        <p>
          请参照右侧示例图，上传正确的裁判员第一页照片。格式要求:裁判员证书带个人照片页，仅支持JPG、JPEG、PNG格式且
          <span style="color: red">小于2M</span>。
        </p>
      </div>
    </div>
    <div class="line"></div>
    <div class="tel">
      <img
        src="../../assets/image/certificate.png"
        alt=""
        style="width: 20px; height: 23px"
      />
      <p class="telnumber">证书第二页</p>
      <p class="asterisk">*</p>
      <div class="upload-box">
        <div class="upload">
          <SingleImage
            @uploadSuccess="handleSuccess2"
            :disabled="step == 3"
            :fileList="fileList2"
            @uploaddelete="uploaddelete"
          ></SingleImage>
          <div class="example">
            <p>正确示范</p>
            <img src="../../assets/image/second.jpg" alt="" />
          </div>
        </div>
        <p>
          请参照右侧示例图，上传正确的裁判员第二页照片。格式要求:裁判员证书带有项目、等级、发证单位、发证日期页，仅支持JPG、JPEG、PNG格式且
          <span style="color: red">小于2M</span>。
        </p>
      </div>
    </div>
    <div class="line"></div>
    <div class="radio" v-if="step != 3">
      <el-radio label="1" v-model="radio" :disabled="step == 3"></el-radio>
      <p>
        本人承诺以上所填内容真实无误，并予以认可，如不真实，本人愿承担相应后果。
      </p>
    </div>
    <div class="button" v-if="step != 3">
      <el-button
        class="save"
        @click="trigger"
        v-if="
          applyForStatus == 30 || applyForStatus == 40 || applyForStatus == 60
        "
        >返回</el-button
      >
      <el-button class="save" @click="save(1)" v-else :loading="loading1"
        >保存为草稿</el-button
      >
      <el-button class="submitt" @click="save(2)" :loading="loading2"
        >提交审核</el-button
      >
    </div>
  </div>
</template>

<script>
import SingleImage from './component/SingleImages.vue'
export default {
  name: '',
  components: {
    SingleImage
  },
  props: {
    step: {
      type: [Number, String],
      default: 2
    },
    id: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      radio: 0,
      info: {
        sports: '',
        refereeLevel: '',
        certificateArea: '',
        certificateUnit: '',
        certificateOnePage: '',
        certificateTwoPage: '',
        userId: '',
        applyForStatus: null,
        certificateDate: ''
      },
      info2: {
        sports: '',
        refereeLevel: '',
        certificateArea: '',
        certificateUnit: '',
        certificateOnePage: '',
        certificateTwoPage: '',
        userId: '',
        id: null,
        applyForStatus: null,
        certificateDate: ''
      },
      fileList1: [],
      fileList2: [],
      applyForStatus: 0,
      loading1: false,
      loading2: false
    }
  },
  created() {
    this.sportEventList()
    this.judgmentLevel()
    this.areaList()
    this.unitType()
    if (this.step != 2) {
      this.applyForStatus = this.id.applyForStatus
      this.info.sports = this.id.sports
      this.info.refereeLevel = this.id.refereeLevel
      this.info.certificateArea = this.id.certificateArea
      this.info.certificateUnit = this.id.certificateUnit
      this.info.certificateOnePage = this.id.certificateOnePage
      this.info.certificateTwoPage = this.id.certificateTwoPage
      this.info.certificateDate = this.id.certificateDate

      if (this.id.certificateOnePage != null) {
        this.fileList1 = [{
          name: this.id.certificateOnePage,
          url: this.id.certificateOnePage
        }]
      }
      if (this.id.certificateTwoPage != null) {
        this.fileList2 = [{
          name: this.id.certificateTwoPage,
          url: this.id.certificateTwoPage
        }]
      }

      this.info2.sports = this.id.sports
      this.info2.refereeLevel = this.id.refereeLevel
      this.info2.certificateArea = this.id.certificateArea
      this.info2.certificateUnit = this.id.certificateUnit
      this.info2.certificateOnePage = this.id.certificateOnePage
      this.info2.certificateTwoPage = this.id.certificateTwoPage
      this.info2.certificateDate = this.id.certificateDate
    }
    console.log(this.id)
  },
  methods: {
    handleSuccess1(e) {
      this.info.certificateOnePage = e.message
    },
    handleSuccess2(e) {
      this.info.certificateTwoPage = e.message
    },
    uploaddelete(e) {
      if (e == this.info.certificateOnePage) {
        this.info.certificateOnePage = ''
      } else if (e == this.info.certificateTwoPage) {
        this.info.certificateTwoPage = ''
      }
    },
    sportEventList() {
      this.$api.sportEventList({ dictValue: '其他' }).then(res => {
        this.options1 = res.data.data.result
      })
    },
    judgmentLevel() {
      this.$api.judgmentLevel().then(res => {
        this.options2 = res.data.data.result
      })
    },
    areaList() {
      this.$api.areaList().then(res => {
        this.options3 = res.data.data.result
      })
    },
    unitType() {
      this.$api.unitType().then(res => {
        this.options4 = res.data.data.result
      })
    },
    save(e) {
      this.info.userId = this.$store.state.userid
      if (this.$verification(this.info.sports)) {
        return this.$message.error('请选择运动项目')
      }
      else if (this.$verification(this.info.refereeLevel)) {
        return this.$message.error('请选择裁判员级别')
      }
      else if (this.$verification(this.info.certificateArea)) {
        return this.$message.error('请选择注册所在地')
      }
      else if (this.$verification(this.info.certificateUnit)) {
        return this.$message.error('请选择发证单位')
      }
      else if (this.$verification(this.info.certificateDate)) {
        return this.$message.error('请选择发证日期')
      }
      else if (this.$verification(this.info.certificateOnePage)) {
        return this.$message.error('请上传证书第一页')
      }
      else if (this.$verification(this.info.certificateTwoPage)) {
        return this.$message.error('请上传证书第二页')
      }
      else if (this.radio == 0) {
        return this.$message.error('请勾选下方本人承诺')
      }

      let month = this.info.certificateDate.match(/-(\d{2})-(\d{2})$/)
      if (month) {
        this.info.certificateDate = this.info.certificateDate
      } else {
        this.info.certificateDate = this.info.certificateDate + '-01'
      }

      if (this.step == 2) { // 添加
        if (e == 1) {
          this.loading1 = true
          this.info.applyForStatus = 10
        } else {
          this.loading2 = true
          this.info.applyForStatus = 20
        }

        let encryptedInfo = {
          sports: this.$encrypt(this.info.sports),
          refereeLevel: this.$encrypt(this.info.refereeLevel),
          certificateArea: this.$encrypt(this.info.certificateArea),
          certificateUnit: this.$encrypt(this.info.certificateUnit),
          certificateOnePage: this.$encrypt(this.info.certificateOnePage),
          certificateTwoPage: this.$encrypt(this.info.certificateTwoPage),
          userId: this.$encrypt(this.info.userId),
          applyForStatus: this.$encrypt(this.info.applyForStatus),
          avatar: this.$encrypt(this.$store.state.realnameornot.headPic),
          certificateDate: this.info.certificateDate
        }

        this.$api.registerRefereeCertificate(encryptedInfo).then(res => {
          if (e == 1) {
            this.loading1 = false
            this.$message.success('保存成功')
          } else {
            this.loading2 = false
            this.$message.success('提交成功')
          }
          this.trigger()
        }).catch(() => {
          if (e == 1) {
            this.loading1 = false
          } else {
            this.loading2 = false
          }
        })
      } else if (this.step == 5) { // 草稿编辑
        this.info.id = this.id.id

        if (e == 1) {
          this.loading1 = true
          this.info.applyForStatus = 10
          let encryptedInfo = {
            sports: this.$encrypt(this.info.sports),
            refereeLevel: this.$encrypt(this.info.refereeLevel),
            certificateArea: this.$encrypt(this.info.certificateArea),
            certificateUnit: this.$encrypt(this.info.certificateUnit),
            certificateOnePage: this.$encrypt(this.info.certificateOnePage),
            certificateTwoPage: this.$encrypt(this.info.certificateTwoPage),
            userId: this.$encrypt(this.info.userId),
            applyForStatus: this.$encrypt(this.info.applyForStatus),
            id: this.$encrypt(this.info.id),
            avatar: this.$encrypt(this.$store.state.realnameornot.headPic),
            certificateDate: this.info.certificateDate
          }

          this.$api.updateRefereeCertificate(encryptedInfo).then(res => {
            this.$message.success('保存成功')
            this.loading1 = false
            this.trigger()
          }).catch(() => {
            this.loading1 = false
          })
        } else {
          this.loading2 = true
          this.info.applyForStatus = 20
          let encryptedInfo = {
            sports: this.$encrypt(this.info.sports),
            refereeLevel: this.$encrypt(this.info.refereeLevel),
            certificateArea: this.$encrypt(this.info.certificateArea),
            certificateUnit: this.$encrypt(this.info.certificateUnit),
            certificateOnePage: this.$encrypt(this.info.certificateOnePage),
            certificateTwoPage: this.$encrypt(this.info.certificateTwoPage),
            userId: this.$encrypt(this.info.userId),
            applyForStatus: this.$encrypt(this.info.applyForStatus),
            avatar: this.$encrypt(this.$store.state.realnameornot.headPic),
            id: this.$encrypt(this.info.id),
            certificateDate: this.info.certificateDate
          }

          this.$api.registerRefereeCertificate(encryptedInfo).then(res => {
            this.loading2 = false
            this.$message.success('提交成功')
            this.trigger()
          }).catch(() => {
            this.loading2 = false
          })
        }
      } else if (this.step == 6) { // 年度注册

        if (this.$verification(this.id.certificateDate)) {
          console.log('发证日期原始数据为空', this.info.applyForStatus)

          if (this.info.sports == this.info2.sports &&
            this.info.refereeLevel == this.info2.refereeLevel &&
            this.info.certificateArea == this.info2.certificateArea &&
            this.info.certificateUnit == this.info2.certificateUnit &&
            this.info.certificateTwoPage == this.info2.certificateTwoPage &&
            this.info.certificateOnePage == this.info2.certificateOnePage
          ) { // 判断是否修改过
            this.info.id = this.id.id

            if (e == 1) {
              this.loading1 = true
              this.info.applyForStatus = 10
            } else {
              this.loading2 = true
              this.info.applyForStatus = 40
            }

            let encryptedInfo = {
              sports: this.$encrypt(this.info.sports),
              refereeLevel: this.$encrypt(this.info.refereeLevel),
              certificateArea: this.$encrypt(this.info.certificateArea),
              certificateUnit: this.$encrypt(this.info.certificateUnit),
              certificateOnePage: this.$encrypt(this.info.certificateOnePage),
              certificateTwoPage: this.$encrypt(this.info.certificateTwoPage),
              userId: this.$encrypt(this.info.userId),
              applyForStatus: this.$encrypt(this.info.applyForStatus),
              id: this.$encrypt(this.info.id),
              avatar: this.$encrypt(this.$store.state.realnameornot.headPic),
              certificateDate: this.info.certificateDate
            }
            console.log('发证日期原始数据为空未修改', this.info.applyForStatus)
            this.$api.refereeCertificateUpdateSubmit(encryptedInfo).then(res => {
              if (e == 1) {
                this.loading1 = false
                this.$message.success('保存成功')
              } else {
                this.loading2 = false
                this.$message.success('提交成功')
              }
              this.trigger()
            }).catch(() => {
              if (e == 1) {
                this.loading1 = false
              } else {
                this.loading2 = false
              }
            })
          } else {
            this.info.id = this.id.id

            if (e == 1) {
              this.loading1 = true
              this.info.applyForStatus = 10
            } else {
              this.loading2 = true
              this.info.applyForStatus = 20
            }

            let encryptedInfo = {
              sports: this.$encrypt(this.info.sports),
              refereeLevel: this.$encrypt(this.info.refereeLevel),
              certificateArea: this.$encrypt(this.info.certificateArea),
              certificateUnit: this.$encrypt(this.info.certificateUnit),
              certificateOnePage: this.$encrypt(this.info.certificateOnePage),
              certificateTwoPage: this.$encrypt(this.info.certificateTwoPage),
              userId: this.$encrypt(this.info.userId),
              applyForStatus: this.$encrypt(this.info.applyForStatus),
              id: this.$encrypt(this.info.id),
              avatar: this.$encrypt(this.$store.state.realnameornot.headPic),
              certificateDate: this.info.certificateDate
            }
            console.log('发证日期原始数据为空已修改', this.info.applyForStatus)
            this.$api.refereeCertificateUpdateSubmit(encryptedInfo).then(res => {
              if (e == 1) {
                this.loading1 = false
                this.$message.success('保存成功')
              } else {
                this.loading2 = false
                this.$message.success('提交成功')
              }
              this.trigger()
            }).catch(() => {
              if (e == 1) {
                this.loading1 = false
              } else {
                this.loading2 = false
              }
            })
          }
        } else {
          console.log('发证日期原始数据不为空', this.info.applyForStatus)

          if (this.info.sports == this.info2.sports &&
            this.info.refereeLevel == this.info2.refereeLevel &&
            this.info.certificateArea == this.info2.certificateArea &&
            this.info.certificateUnit == this.info2.certificateUnit &&
            this.info.certificateTwoPage == this.info2.certificateTwoPage &&
            this.info.certificateOnePage == this.info2.certificateOnePage &&
            this.info.certificateDate == this.info2.certificateDate
          ) { // 判断是否修改过
            this.info.id = this.id.id

            if (e == 1) {
              this.loading1 = true
              this.info.applyForStatus = 10
            } else {
              this.loading2 = true
              this.info.applyForStatus = 40
            }

            let encryptedInfo = {
              sports: this.$encrypt(this.info.sports),
              refereeLevel: this.$encrypt(this.info.refereeLevel),
              certificateArea: this.$encrypt(this.info.certificateArea),
              certificateUnit: this.$encrypt(this.info.certificateUnit),
              certificateOnePage: this.$encrypt(this.info.certificateOnePage),
              certificateTwoPage: this.$encrypt(this.info.certificateTwoPage),
              userId: this.$encrypt(this.info.userId),
              applyForStatus: this.$encrypt(this.info.applyForStatus),
              id: this.$encrypt(this.info.id),
              avatar: this.$encrypt(this.$store.state.realnameornot.headPic),
              certificateDate: this.info.certificateDate
            }
            console.log('发证日期原始数据不为空未修改', this.info.applyForStatus)
            this.$api.refereeCertificateUpdateSubmit(encryptedInfo).then(res => {
              if (e == 1) {
                this.loading1 = false
                this.$message.success('保存成功')
              } else {
                this.loading2 = false
                this.$message.success('提交成功')
              }
              this.trigger()
            }).catch(() => {
              if (e == 1) {
                this.loading1 = false
              } else {
                this.loading2 = false
              }
            })
          } else {
            this.info.id = this.id.id

            if (e == 1) {
              this.loading1 = true
              this.info.applyForStatus = 10
            } else {
              this.loading2 = true
              this.info.applyForStatus = 20
            }

            let encryptedInfo = {
              sports: this.$encrypt(this.info.sports),
              refereeLevel: this.$encrypt(this.info.refereeLevel),
              certificateArea: this.$encrypt(this.info.certificateArea),
              certificateUnit: this.$encrypt(this.info.certificateUnit),
              certificateOnePage: this.$encrypt(this.info.certificateOnePage),
              certificateTwoPage: this.$encrypt(this.info.certificateTwoPage),
              userId: this.$encrypt(this.info.userId),
              applyForStatus: this.$encrypt(this.info.applyForStatus),
              id: this.$encrypt(this.info.id),
              avatar: this.$encrypt(this.$store.state.realnameornot.headPic),
              certificateDate: this.info.certificateDate
            }
            console.log('发证日期原始数据不为空已修改', this.info.applyForStatus)
            this.$api.refereeCertificateUpdateSubmit(encryptedInfo).then(res => {
              if (e == 1) {
                this.loading1 = false
                this.$message.success('保存成功')
              } else {
                this.loading2 = false
                this.$message.success('提交成功')
              }
              this.trigger()
            }).catch(() => {
              if (e == 1) {
                this.loading1 = false
              } else {
                this.loading2 = false
              }
            })
          }
        }

      } else {
        this.info.id = this.id.id

        if (e == 1) {
          this.loading1 = true
          this.info.applyForStatus = 10
        } else {
          this.loading2 = true
          this.info.applyForStatus = 20
        }

        let encryptedInfo = {
          sports: this.$encrypt(this.info.sports),
          refereeLevel: this.$encrypt(this.info.refereeLevel),
          certificateArea: this.$encrypt(this.info.certificateArea),
          certificateUnit: this.$encrypt(this.info.certificateUnit),
          certificateOnePage: this.$encrypt(this.info.certificateOnePage),
          certificateTwoPage: this.$encrypt(this.info.certificateTwoPage),
          userId: this.$encrypt(this.info.userId),
          applyForStatus: this.$encrypt(this.info.applyForStatus),
          id: this.$encrypt(this.info.id),
          avatar: this.$encrypt(this.$store.state.realnameornot.headPic),
          certificateDate: this.info.certificateDate
        }

        this.$api.refereeCertificateUpdateSubmit(encryptedInfo).then(res => {
          if (e == 1) {
            this.loading1 = false
            this.$message.success('保存成功')
          } else {
            this.loading2 = false
            this.$message.success('提交成功')
          }
          this.trigger()
        }).catch(() => {
          if (e == 1) {
            this.loading1 = false
          } else {
            this.loading2 = false
          }
        })
      }
    },
    trigger() {
      this.$emit('toexamine')
      this.info = {
        sports: '',
        refereeLevel: '',
        certificateArea: '',
        certificateUnit: '',
        certificateNum: '',
        certificateOnePage: '',
        certificateTwoPage: '',
        userId: '',
        id: null,
        certificateDate: ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tel {
	display: flex;
	padding-top: 27PX;
	margin-left: 79PX;
}

.tel img {
	width: 17.3PX;
	height: 27.7PX;
}

.telnumber {
	font-size: 18PX;
	font-weight: 400;
	color: #333333;
	margin-left: 25PX;
  flex-shrink: 0;
}

.tel .asterisk {
	font-size: 25PX;
	font-weight: 400;
	color: #E6101C;
	margin-left: 5PX;
	margin-right: 59PX;
}

.tel input {
	width: 312PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	padding-left: 10PX;
	font-size: 18PX;
}

.tel ::v-deep .el-input__inner {
	width: 312PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	font-size: 18PX;
	color: #333333;
}

.tel ::v-deep input::-webkit-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-moz-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-ms-input-placeholder {
	color: #333333;
}

.tel ::v-deep .el-select .el-input .el-select__caret {
	color: #191919;
}

.upload-box {
	display: flex;
	flex-direction: column;
}

.upload {
	display: flex;
}

.upload ::v-deep .el-upload-dragger {
	width: 195PX;
	height: 127PX;
	border: 1PX dashed #2368E1;
}

.example {
	display: flex;
}

.example p {
	font-size: 18PX !important;
	font-weight: 500;
	color: rgba(10, 109, 243, 0.58) !important;
	margin-left: 77PX;
	margin-top: 0 !important;
}

.example img {
	width: 194PX;
	height: 126PX;
	border-radius: 16PX;
	margin-left: 31PX;
}

.upload-box p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
	margin-top: 40PX;

}

.line {
	background: rgba(37, 44, 53, 0.1);
	height: 1PX;
	margin-top: 31PX;
}

::v-deep .el-radio__label {
	display: none;
}

::v-deep .el-radio__inner {
	width: 19PX;
	height: 19PX;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
	border-color: #0A6DF3;
	background: #0A6DF3;
}

.el-radio {
	margin-right: 5PX !important;
}

.radio {
	display: flex;
	align-items: center;
	margin-left: 116PX;
	margin-top: 87PX;
	margin-bottom: 57PX;
}

.radio p {
	font-size: 18PX;
	font-weight: 400;
	color: #333333;
}

.button {
	display: flex;
	margin-left: 152PX;
	margin-bottom: 100PX;
}

.save {
	width: 320.2PX;
	height: 67PX;
	background-image: linear-gradient(to right, #6FB2FF, #095FFF);
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.submitt {
	width: 320.2PX;
	height: 67PX;
	background-image: linear-gradient(to right, #6FB2FF, #095FFF);
	margin-left: 67PX;
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.el-select-dropdown__item {
	font-size: 18PX;
}

::v-deep .el-input__prefix {
	display: none;
}

::v-deep .el-input--prefix .el-input__inner {
	padding: 0 15PX;
}
</style>