<template>
  <div class="app-container">
    <div>

      <div class="uploadbox" v-if="fileList1.length === 0 || isEmpty">
        <el-upload ref='upload' :drag="true" :limit="1" :data="upData" :action="uploadUrl" :on-success="success" :headers="headers"
          :multiple="false" :on-preview="preview" :disabled="disabled" :file-list="uploadfiles" :show-file-list="false"
          :before-upload="beforeAvatarUpload" accept="image/jpg,image/jpeg,image/png">
          <img src="../../../assets/image/upload.png" class="upload" alt="">
          <div class="el-upload__text">
            上传
          </div>
        </el-upload>
      </div>

      <div v-else class="box">
        <div class="icon">
          <i class="el-icon-error" @click="cleanup" v-if="!disabled"></i>
          <i class="el-icon-zoom-in" @click="lookover"></i>
        </div>
        <div class="image">
          <img :src="src" class="img" @click="lookover">
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'uploadUI',
  props: {
    fileExtensionList: {
      type: Array,
      default() {
        return ['jpg', 'png']
      }
    },
    limit: {
      type: Number,
      default: 1
    },
    multiple: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Object,
      default() {
        return {
          "token": this.$store.state.token
        }
      }
    },
    message: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fileList: { //文件列表
      type: Array,
      default: () => [] // 工厂函数 不然报错
    }
  },
  watch: {
    fileList: {
      handler(newVal, oldVal) { // 回显
        this.fileList1 = newVal
        this.uploadfiles = newVal
        if (newVal[0] !== undefined && newVal.length !== 0) {
          this.$api.getFile({ objectName: newVal[0].url }).then(res => {
            // this.src = res.data.data.result.url
            axios({
              method: "POST",
              data: {
                url: res.data.data.result.url
              },
              url: process.env.VUE_APP_BASE_API + '/api/download',
              //我需要让他返回的格式为blod
              responseType: "blob",
              headers: {
                'Content-Type': 'application/json; charset=UTF-8'
              },
            }).then((result) => {
              var binaryData = [];
              binaryData.push(result.data);
              let url = window.URL.createObjectURL(
                new Blob(binaryData, {
                  type: result.data.type,
                })
              );
              this.src = url
            })
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      uploadUrl: '',
      upData: {
        fileExtensionList: ["jpg", "png"],
      },
      fileList1: [],
      type: false, //判断照片还是pdf
      src: '', // 链接
      uploadfiles: this.fileList,
      isEmpty: false // 判断是否为空
    }
  },
  mounted() {
    this.uploadUrl = process.env.VUE_APP_BASE_API + '/api/upload';
    // this.uploadUrl = location.href.split('/')[0] + '//' + location.href.split('/')[2] + '/prod-api/api/upload';
    this.upData.fileExtensionList = this.fileExtensionList;
  },
  methods: {
    beforeAvatarUpload(file) {
      let index = file.type.lastIndexOf("/")
      let ext = file.type.substr(index + 1)
      let formats = ['png', 'jpg', 'jpeg', 'bmp']
      const isLt5M = file.size / 1024 / 1024 < 2

      const isJPG = formats.includes(ext)

      if (!isJPG) {
        this.$message.error('文件格式不符合！仅支持png、jpg、jpeg、bmp格式');
      } else if (!isLt5M) {
        this.$message({
          message: `上传文件大小不能超过2M!`,
          type: 'error'
        })
      }
      return isJPG && isLt5M;
    },
    success(response, file, fileList) {
      if (response.code == -1) {
        this.$alert('登录状态已过期，请重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          type: 'warning'
        }).then(async () => {
          //注销去登录页
          this.$store.commit("EXIT_TOKEN")
          if (window.location.href.includes('/h5')) {
            location.href = '/h5/login'
          } else {
            location.href = '/login'
          }
        })
        this.$refs.upload.clearFiles()
        return
      }

      if(response.code != 200){
        this.$refs.upload.clearFiles()
        return this.$message.error(response.msg)
      }
      // upload 为父组件当中的方法
      if (file.raw.type == 'application/json') { // 上传判断
        this.isEmpty = true
        this.uploadfiles = []
        this.fileList1 = []
      } else {
        this.isEmpty = false
        this.fileList1 = [{ name: response.data.message, url: response.data.message }]
        this.uploadfiles = [{ name: response.data.message, url: response.data.message }]
      }
      this.type = file.raw.type
      this.preview(file)
      this.$emit('uploadSuccess', response.data);
    },
    preview(file) { // 上传转换
      let reader = new FileReader()
      reader.readAsDataURL(file.raw)
      let _this = this
      reader.onload = function () {
        let obj = binaryFun(reader.result);
        let blob = new Blob([obj.u8arr], { type: obj.mime })

        let url = URL.createObjectURL(blob)
        let index = file.raw.type.lastIndexOf("/")
        let ext = file.raw.type.substr(index + 1)
        let formats = ['png', 'jpg', 'jpeg', 'bmp']
        _this.type = formats.includes(ext)
        if (formats.includes(ext)) { // 判断是否是照片
          _this.src = url
        } else {
          _this.src = url
        }
      }
      const binaryFun = binary => {
        let arr = binary.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return { u8arr, mime }
      }
    },
    cleanup() { // 删除
      let that = this
      this.$confirm('确定移除当前照片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$emit('uploaddelete', that.uploadfiles[0].url)
        console.log(that.uploadfiles)
        that.fileList1 = []
        that.uploadfiles = []
      }).catch(() => { })
      return
    },
    lookover() {
      window.open(this.src)
    }
  }
}
</script>
<style  scoped>
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload {
  margin: auto;
  /* border: solid 1PX; */
}

.tips {
  color: red;
}

.uploadbox {
  /* width: 360PX; */
  margin: auto;
}

.box {
  background-color: #fff;
  border: 1PX dashed #d9d9d9;
  border-radius: 6PX;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 195PX;
  height: 145PX;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.icon {
  margin: 10PX;
  font-size: 20PX;
  position: absolute;
  right: 10PX;
  color: #1890ff;
}

.el-icon-zoom-in {
  margin-left: 5PX;
}

.image {
  width: 195PX;
  height: 145PX;

}

.img {
  width: 100%;
  height: 100%;
}

.upload {
  width: 33.9PX;
  height: 33.9PX;
  margin-top: 35PX;
}

.el-upload__text {
  font-size: 18PX;
  font-weight: 400;
  color: #000000;
  margin-top: 20PX;
}

::v-deep .el-upload-dragger {
  height: 145PX !important;
}</style>